@font-face {
  font-family: "Strawberry Dreams";
  src: url(./fonts/strawberry_dreams.otf);
  font-weight: normal;
}
@font-face {
  font-family: "Strawberry Dreams Block";
  src: url(./fonts/strawberry_dreams_block.otf);
  font-weight: normal;
}
@font-face {
  font-family: "Strawberry Dreams Alt";
  src: url(./fonts/strawberry_dreams_alternates.otf);
  font-weight: normal;
}

:root {
  --brand-color: #b0c9d6;
  --heading-color: rgb(96, 106, 39);
  --heading-font: "Strawberry Dreams Block";
  --main-font: Helvetica;
}

a {
  color: var(--heading-color);
}

a:hover {
  color: var(--brand-color);
}

/* Bootstrap override */

a.btn.btn-primary {
  background-color: rgb(96, 106, 39);
  border-color: rgb(96, 106, 39);
  color: var(--brand-color);
  width: 100%;
}
.btn-primary:hover {
  background-color: rgb(96, 106, 39);
  border-color: var(--brand-color);
  color: var(--brand-color);
}

/* ------------------------ */

/* BackgroundImage */
.background-container {
  position: fixed;
  height: 100vh;
}

.background-image {
}

/* ------------------------------------ */

h2,
h1 {
  font-family: "Strawberry Dreams Block";
  color: var(--heading-color);
}

/* TopNav */
.navbar-brand img {
  max-width: 50px;
  margin-right: 8px;
}

.navbar-brand {
  font-family: var(--heading-font);
  font-size: 1.8rem;
  color: var(--heading-color);
}

a.nav-link {
  color: var(--heading-color);
  font-family: var(--main-font);
}

a.nav-link:hover {
  color: var(--brand-color);
  border-bottom: solid var(--brand-color) 2px;
}

/* ------------------------------------ */

/* HomeHeader */
h1.hero {
  color: var(--brand-color);
  font-weight: bold;
  font-family: var(--heading-font);
  background-color: rgb(96, 106, 39, 0.6);
  padding: 20px;
  border-radius: 5px;
}

/* ------------------------------------ */

/* Values */
h2.values {
  text-align: center;
  font-family: var(--heading-font);
  font-size: 3.3rem;
}

.value-name {
  font-size: 1.7rem;
  font-family: var(--heading-font);
}

.value-description {
  font-family: var(--main-font);
  font-size: 1.3rem;
  font-weight: 600;
  background-color: var(--heading-color);
  border-radius: 5px;
  margin: 5px;
  width: 100%;
}

.value-img img {
  border-radius: 8px;
}
/* ------------------------------------ */

/* JoinAndAbout */
.join-and-about div {
  font-family: var(--heading-font);
  font-size: 2.3rem;
  letter-spacing: 3px;
  text-align: center;
}

.join-and-about .btn {
  font-size: 1.5rem;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* --------------------------------------- */

/* Footer */
footer {
  color: white;
}

h5.footer {
  font-family: var(--heading-font);
}

/* ------------------------------------ */

/* Contact */
.contact-section {
  margin-top: 50px;
}

#home-telegram-link {
  font-size: 1rem;
}

/* ---------------------------------------- */
/* Media Queries */
@media (max-width: 575.98px) {
  .value-description {
    height: 150px;
  }

  .join-and-about div {
    font-size: 1.7rem;
  }

  .navbar-brand {
    font-size: 1.4rem;
  }

  .join-and-about .btn {
    width: 30%;
    font-size: 0.8rem;
  }

  .value-item {
    border-bottom: solid;
    padding-bottom: 5px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .value-description {
    height: 230px;
  }

  .join-and-about .btn {
    width: 30%;
    font-size: 1.2rem;
  }

  .value-item {
    border-bottom: 2px solid;
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .value-description {
    height: 230px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .value-description {
    height: 250px;
  }
}

@media (min-width: 1200px) {
  .value-description {
    height: 200px;
  }
}
