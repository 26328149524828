.about-page {
  max-width: 700px;
  margin: 0 auto;
  padding: 50px 0;
}

h1 {
  font-size: 2.9rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2rem;
  margin-top: 50px;
  margin-bottom: 20px;
}

p,
li {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 25px;
}
